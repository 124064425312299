import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import BootstrapModal from '../../../component/modal/bootstrap-modal';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import SalaryTypeSettingsForm from "./salary-type-settings-form";

function SalaryTypeSettings(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [showDetails, setShowDetails] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [salarySettingsItems, setSalarySettingsItems] = useState([]);
    const [salarySettings, setSalarySettings] = useState([]);
    const [salaryType, setSalaryType] = useState([]);
    const columns = ["SN", "SALARY TYPE", "GRADE", "SCALE", "ITEM NAME", "AMOUNT"];
    const controller = new AbortController();

    const [formData, setFormData] = useState({
        salary_type_id: "",
        salary_step: "",
        salary_rank: "",
        salary_name: "",
        inserted_by: props.loginData[0]?.employee_id,
    })


    const getData = async () => {
        await axios.get(`${API_URL}human-resources/salary-type-settings/meta-data`, login?.token)
            .then((res) => {
                if (res.data.message === 'success') {
                    if (res.data.salaryTypeSettings.length > 0) {
                        let rows = [];
                        res.data.salaryTypeSettings.map((x, i) => {
                            rows.push([
                                i + 1,
                                x.salary_name,
                                x.grade,
                                x.step,
                                x.item_name,
                                x.amount
                            ])
                        })
                        setTableData(rows)
                    }
                    setSalarySettingsItems(res.data.salarySettingsItems)
                    setSalarySettings(res.data.salarySettings)
                    setSalaryType(res.data.salaryType)
                }
                setIsLoading(false);
            }).catch((e) => {
                toast.error(e.response.data)
            })
    }

    useEffect(()=>{
        getData();
    }, [])


    const onChange = (e) => {
        setShowDetails(false)
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
        setTimeout(()=>{
            setShowDetails(true)
        }, 1000)
    }

    const onOpenModal = (status) => {
        setFormData({
            ...formData,
            salary_type_id: "",
            salary_step: "",
            salary_rank: "",
            salary_name: "",
        })
        setOpenModal(status);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        toast.info("please wait...")

        let AmountArray = [];
        let ItemIdArray = [];
        let input = document.getElementsByClassName("score");
        for (let i = 0; i < input.length; i++) {
            AmountArray.push(input[i].value)
            ItemIdArray.push(input[i].getAttribute("item_id"))
        }

        const SendData = {...formData, item_id: ItemIdArray, item_amount: AmountArray}
        await axios.post(`${API_URL}human-resources/salary-type-settings/add`, SendData, login?.token)
            .then((res) => {
                if (res.data.message === "success") {
                    toast.success("Salary type settings added successfully");
                    onOpenModal(false);
                    getData();
                }else {
                    toast.warning("Something went wrong, please try again!");
                }
            }).catch((e) => { toast.error(e.response.data) })

    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Salary Type Settings"
                                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Employee Management', link: '/' },
                                    { name: 'Salary Type Settings', link: '/employee-management/salary-type-settings' }]}
                                button={<button className="btn btn-primary" onClick={() => { onOpenModal(true) }}> + Add Salary Type Settings</button>}
                />
                <BootstrapModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    title="Salary Type Settings">
                    <SalaryTypeSettingsForm
                        values={formData}
                        salarySettingsItems={salarySettingsItems}
                        salarySettings={salarySettings}
                        salaryType={salaryType}
                        setSalarySettingsItems={setSalarySettingsItems}
                        setSalarySettings={setSalarySettings}
                        showDetails={showDetails}
                        onChange={onChange}
                        onSubmit={onSubmit}
                    />
                </BootstrapModal>

                <ReportTable title={"Salary Type Settings"} columns={columns} data={tableData} height={"700px"} />

            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(SalaryTypeSettings);
