import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { connect } from 'react-redux';
import { createRoutesFromElements } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import BootstrapModal from '../../../component/modal/bootstrap-modal';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import {Audit, currencyConverter, formatDateAndTime, showConfirm} from '../../../context/function';
import PaySlipPrint from './pay-slip/pay-slip';
import PayrollSidebar from "../../../component/payroll-sidebar/payroll-sidebar";

function SalaryReport(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [salaryDetails, setSalaryDetails] = useState([])
    const [salaryDetails2, setSalaryDetails2] = useState([])
    const [employeeID, setEmployeeID] = useState([])
    const columns = ["S/N", "EMPLOYEE ID", "EMPLOYEE", "SALARY DATE", "ACCOUNT NUMBER", "BANK", "ACTIONS"];
    const detailsColumns = ["S/N", "ITEM", "TYPE", "AMOUNT", "DATE"]
    const [printPaySlip, setprintPaySlip] = useState(false)
    const componentRef = useRef();


    const [salaryMonths, setSalaryMonths] = useState([])

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        month_id: "",
        inserted_by: props.loginData[0]?.employee_id,
    })

    const getData = async () => {

        await axios.get(`${API_URL}human-resources/report/salary-months`, login?.token).then((res) => {
            setSalaryMonths(res.data)
            setIsLoading(false)
        }).catch((e) => {
            toast.error(e.response.data)
        })

    }
    const [fetechingRecord, setFetchingRecord] = useState(null)

    const getSalaryReport = async (salary_month) => {

        if (salary_month === "") {
            setTableData([])
        } else {
            setIsSubmitting(true)
            await axios.get(`${API_URL}human-resources/report/salary/summary?salary_month=${salary_month}`, login?.token)
                .then((res) => {
                    if (res.data.length > 0) {
                        let rows = [];
                        let row2 = [];
                        let ft;
                        res.data.map((x, i) => {
                            row2.push(x.employee_id)
                            rows.push([
                                i + 1,
                                x.employee_id.toString().padStart(4, "0"),
                                // x.employee_name + ` (${x.employee_id})`,
                                x.employee_name,
                                // currencyConverter(x.total_pay),
                                formatDateAndTime(x.salary_date, "month_and_year"),
                                x.account_number,
                                x.bank_name,
                               <div className='d-flex'>
                                   < button
                                       className='btn  btn-primary m-lg-2'
                                       onClick={async () => {
                                           setSalaryDetails([])
                                           onOpenModal(true)
                                           setFetchingRecord(x.employee_id)
                                           setFormData({
                                               employee_id: x.employee_id.toString().padStart(4, "0"),
                                               employee_name: x.employee_name,
                                               salary_date: x.salary_date,
                                               total_pay: x.total_pay,
                                               run_date: x.inserted_date

                                           })
                                           await axios.get(`${API_URL}human-resources/report/salary/details/${x.employee_id}/${x.salary_date}`, login?.token).then((results) => {
                                               if (results.data.length > 0) {
                                                   let det_rows = []
                                                   setSalaryDetails2(results.data)
                                                   results.data.map((det, index) => {
                                                       det_rows.push([
                                                           index + 1,
                                                           det.item_name,
                                                           <span className={det.salary_type === "Allowance" ? "text-success" : "text-danger"}>
                                                            {det.salary_type}
                                                        </span>,
                                                           <span className={det.salary_type === "Allowance" ? "text-success" : "text-danger"}>
                                                            {currencyConverter(det.amount)}
                                                        </span>,
                                                           formatDateAndTime(det.salary_date, "month_and_year")
                                                       ])
                                                   })
                                                   setSalaryDetails(det_rows)
                                               } else {
                                                   setSalaryDetails([])
                                               }
                                               setFetchingRecord(null)
                                           })
                                       }}
                                   >

                                       <i className='fa fa-eye' />
                                       &nbsp; {fetechingRecord !== null ? "loading" : "Print Split"}
                                   </button >
                                   < button
                                       className='btn btn-sm btn-success'
                                       onClick={ () => {
                                            axios.get(`${API_URL}human-resources/payroll/salary/pay-slip/${x.employee_id}/${x.salary_date}`, login?.token).then((results) => {
                                               if (results.data.message === "success"){
                                                   toast.success("Salary Slip Sent Successfully");
                                               }else{
                                                   toast.error("Salary Slip Not Sent");
                                               }
                                           })
                                       }}
                                   >

                                       <i className='fa fa-mail-forward' />
                                       &nbsp; { "Send Slip"}
                                   </button >
                               </div>
                            ])
                        })
                        setEmployeeID(row2)
                        setTableData(rows)
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error("error getting allowances")
                })
        }
    }

    useEffect(() => {
        getData();
        Audit(props.loginData[0]?.employee_id, 'Viewed Salary Slip');
    }, [])


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        getSalaryReport(val)
    }

    const onOpenModal = (status) => {
        setOpenModal(status);
    }

    const onPrintPaySlip = () => {
        setprintPaySlip(true);
        setTimeout(() => {
            handlePrint();
            setprintPaySlip(false);
        }, 100);
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onSendAll = () => {
        showConfirm("Warning", `Send Multiple Salary Slip For ${formatDateAndTime(formData.month_id, "month_and_year")} ?`, "warning")
            .then((confirm) => {
                if (confirm) {
                    onSubmit()
                }
            })
    }

   const  onSubmit = () => {
       axios.post(`${API_URL}human-resources/payroll/salary/bulk/pay-slip/${formData.month_id}`, {data: employeeID}, login?.token).then((results) => {
           if (results.data.message === "success"){
               toast.success("Salary Slip Sent Successfully");
           }else{
               toast.error("Salary Slip Not Sent");
           }
       })
   }



    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Salary Report"
                    pages={[{ name: 'Dashboard', link: '/' }, { name: 'Human Resources', link: '/human-resources/payroll' },
                    { name: 'Salary Report', link: '/human-resources/salary-report' }]}
                />

                <div className="row">
                    <PayrollSidebar/>
                    <div className="col-xl-10">
                        <div className=' mb-5'>
                            <div className='card card-body'>
                                <div className="row">
                                    <div className="col-md-12 pb-3">
                                        <div className="form-group">
                                            <label htmlFor="month_id">Select Salary Month</label>
                                            <select className='form-control' id="month_id" onChange={onChange}
                                                    value={formData.month_id} required>
                                                <option value={""}> -select month-</option>
                                                {
                                                    salaryMonths.length > 0 &&
                                                    salaryMonths.map((x, i) => {
                                                        return (
                                                            <option value={x.salary_date}
                                                                    key={i}> {formatDateAndTime(x.salary_date, "month_and_year")} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='mt-5 d-flex justify-content-end align-items-end mb-3 '>
                            {
                                formData.month_id !== "" &&
                                <button
                                    type='button'
                                    disabled={formData.month_id === ""}
                                    className="btn btn-primary w-25" onClick={() => {
                                    onSendAll();
                                }}>
                                    {isSubmitting ? <span> <i className='fa fa-spin fa-spinner'/> &nbsp;Please wait...</span> : "Send Salary slip for all"}
                                </button>
                            }

                        </div>

                        {
                            isSubmitting ?
                                <Loader/>
                                :
                                <ReportTable title={"Salary Report"} columns={columns} data={tableData}
                                             height={"700px"}/>
                        }
                    </div>
                </div>
            </>
            <BootstrapModal
                openModal={openModal}
                closeModal={setOpenModal}
                salaryDetails={salaryDetails}
                title={`SALARY DETAILS FOR ` + formatDateAndTime(formData?.salary_date, "month_and_year") + " | " + formData?.employee_name}>
                {
                    salaryDetails.length > 0 ?
                        <div>
                            {/* <ReportTable
                                data={salaryDetails}
                                columns={detailsColumns}
                            /> */}

                            <div className='table-responsive'>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <td>SN</td>
                                            <td>ITEM</td>
                                            <td>CATEGORY</td>
                                            <td>AMOUNT</td>
                                            <td>DATE</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            salaryDetails2.filter(e=>e.is_printable === 1).length > 0 &&
                                            salaryDetails2.filter(e=>e.is_printable === 1).map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.item_name}</td>
                                                        <td>
                                                            <span className={item.salary_type === "Allowance" ? "text-success" : "text-danger"}>
                                                                {item.salary_type}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className={item.salary_type === "Allowance" ? "text-success" : "text-danger"}>
                                                                {currencyConverter(item.amount)}
                                                            </span>
                                                        </td>
                                                        <td>{formatDateAndTime(item.salary_date, "month_and_year")}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className='mt-5 pt-5 text-center fw-bolder h-100'>
                                {/*<h1>*/}
                                {/*    TOTAL : {currencyConverter(formData?.total_pay)}*/}
                                {/*</h1>*/}
                                <div>
                                    <button
                                        className='btn btn-md btn-primary'
                                        onClick={onPrintPaySlip} >
                                        + Print Pay Slip
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <Loader />
                }

            </BootstrapModal>

            {
                printPaySlip &&
                <PaySlipPrint
                    data={{ salary: salaryDetails2, ...formData }}
                    componentRef={componentRef}
                />
            }

        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(SalaryReport);
