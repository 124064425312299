import {connect} from "react-redux";
import GeneralToolbar from "../../../component/include/general-toolbar";
import {useEffect, useState} from "react";
import { toast } from "react-toastify";
import {API_URL} from "../../../context/connection-string";
import axios from "axios";
import Loader from "../../../component/loader/loader";
import {Audit, decryptData, encryptData, loginValidation} from "../../../context/function";

function ChangeUserPassword(props) {
    const [isLoading, setIsLoading] = useState(true);

    const token = props.loginData[0].token;
    const initialValue = {
        inserted_by: props.loginData[0].employee_id,
        Username: props.loginData[0].employee_id,
        OldPassword: "",
        Password: "",
        CPassword: "",
    };
    const [formData, setFormData] = useState(initialValue);
    const [pass, setPass] = useState('');
    const [loading, setLoading] = useState(false)

    const fetchData = async () => {
        await axios.get(`${API_URL}human-resources/employee/password/${props.loginData[0].employee_id}`, token)
            .then(res => {
                if (res.data.length > 0) {
                    setPass(decryptData(res.data[0].password))
                } else {
                    toast.info("Something went wrong. Please try again!")
                }
                setIsLoading(false)
            })
            .catch(e => {
                toast.error(e.message)
                toast.error(`${e.response.statusText}: ${e.response.data}`)
            })
    }

    useEffect(() => {
        if (loginValidation(window.location.pathname, props.permissionData) !== 'permitted')
            window.location.href = '/';

        fetchData()
    },[])

    const onChangePassword = (e) => {
        e.preventDefault();
        if (formData.OldPassword.toString().trim() === ''){
            toast.error('Please enter old password');
            return false;
        }
        if (formData.Password.toString().trim() === ''){
            toast.error('Please enter new password');
            return false;
        }
        if (formData.CPassword.toString().trim() === ''){
            toast.error('Please enter confirm password');
            return false;
        }
        if (formData.OldPassword !== pass){
            toast.error('Incorrect old password');
            return false;
        }

        if (formData.Password.toString().length < 8){
            toast.error('Password must be 8 or more characters');
            return false;
        }

        if (formData.Password !== formData.CPassword) {
            toast.error('Passwords do not match. Please make sure your passwords match.');
            return false;
        }

        setLoading(true);
        toast.info("Please wait...")

        const data = {
            Username: formData.Username,
            Password: encryptData(formData.Password)
        }
        axios.patch(`${API_URL}human-resources/employee/password/update`, data, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Password Updated Successfully");
                setFormData({
                    ...formData,
                    OldPassword: "",
                    Password: "",
                    CPassword: "",
                })
                fetchData();
                Audit(props.loginData[0]?.employee_id, 'Changed User Password');
                setLoading(false);
            } else {
                setLoading(false);
                toast.error('Something went wrong, please try again.')
            }
        }).catch((e)=>{
            toast.error('Network Error!, please try again.')
        })
    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    return isLoading ? <Loader /> : (
        <>
            <GeneralToolbar title="Change User Password" pages={[{name: 'Dashboard', link: '/'}, {name: 'Settings', link: '/'}, {name: 'Change User Password', link: '/'}]}  />
            {/*begin::Table*/}
            <div className="card">
                <div className="card-body">
                    <form className="form-horizontal m-t-20 col-md-6 offset-sm-3" id="loginform"
                          onSubmit={onChangePassword}>
                        <div className="form-group alert alert-info mb-3">
                            Strong password required. 8-256 characters. Do not include common words or names. Combine uppercase letters, lowercase letters, numbers and symbols.
                        </div>
                        <div className="form-group mb-3">
                            <label>Old Password</label>
                            <input type="password" className="form-control form-control-lg"
                                   id="OldPassword"
                                   onChange={onEdit} value={formData.OldPassword} required
                                   placeholder="Old Password"/>
                        </div>
                        <div className="form-group mb-3">
                            <label>New Password</label>
                            <input type="password" className="form-control form-control-lg"
                                   id="Password"
                                   onChange={onEdit} value={formData.Password} required
                                   placeholder="New Password"/>
                        </div>
                        <div className="form-group mb-3">
                            <label>Confirm Password</label>
                            <input type="password" className="form-control form-control-lg"
                                   id="CPassword"
                                   onChange={onEdit} value={formData.CPassword} required
                                   placeholder="Confirm Password"/>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <div className="custom-control custom-checkbox">
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <div className="col-xs-12 p-b-20">
                                <button className="btn btn-block btn-lg btn-info" disabled={loading} type="submit">UPDATE
                                    PASSWORD
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/*end::Table*/}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        permissionData: state.PermissionDetails
    };
};

export default connect(mapStateToProps, null)(ChangeUserPassword);
