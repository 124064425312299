import React, {useEffect} from 'react'
import {SalaryStepScale} from "../../../context/function";

export default function SalaryTypeSettingsForm(props) {
    const salarySettingsItems = props.salarySettingsItems;
    const salarySettings = props.salarySettings;

    const onIncomeSettingChange = (e, index, data) => {
        let id = e.target.id;
        let value = e.target.value;
        let item_id = e.target.getAttribute('item_id');
    }

    useEffect(() => {

    }, []);

    return (
        <form key={props.showDetails} onSubmit={props.onSubmit}>
            <div className="row">
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="salary_type_id">Salary Type</label>
                        <select className='form-control' id="salary_type_id" onChange={props.onChange}
                                value={props.values.salary_type_id}>
                            <option value={""}> - select salary type -</option>
                            {
                                props.salaryType?.map((x, i) => {
                                    return (
                                        <option key={i} value={x.salary_type_id}> {x.salary_name} </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-6 pb-3">
                    <div className="form-group">
                        <label htmlFor="salary_rank">Rank/Grade Level</label>
                        <select className='form-control' id="salary_rank" onChange={props.onChange}
                                value={props.values.salary_rank}>
                            <option value={""}> - select salary rank/grade level -</option>
                            {
                                SalaryStepScale?.map((x, i) => {
                                    return (
                                        <option key={i} value={x}> {x} </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className="col-md-6 pb-3">
                    <div className="form-group">
                        <label htmlFor="status">Salary Step</label>
                        <select className='form-control' id="salary_step" onChange={props.onChange}
                                value={props.values.salary_step}>
                            <option value={""}> - select salary step -</option>
                            {
                                SalaryStepScale?.map((x, i) => {
                                    return (
                                        <option key={i} value={x}> {x} </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

                {
                    props.values.salary_type_id !=='' && props.values.salary_rank !=='' && props.values.salary_step !=='' ?
                        <>
                            <hr/>
                            <h3>Income and Emoluments</h3>
                            <hr/>
                            {
                                props.showDetails ? <div className="row">

                                    {
                                        props.salarySettingsItems.length > 0 &&
                                        props.salarySettingsItems.filter(q => parseInt(q.is_taxable) === 1).map((x, i) => {
                                            let amount_value = 0;
                                            if (props.salarySettings.length > 0) {
                                                const amount_data = props.salarySettings?.filter(j => parseInt(j.salary_type_id) === parseInt(props.values.salary_type_id) && parseInt(j.settings_item_id) === parseInt(x.item_id) && parseInt(j.grade) === parseInt(props.values.salary_rank) && parseInt(j.step) === parseInt(props.values.salary_step))
                                                amount_value = amount_data.length > 0 ? amount_data[0].amount : 0;
                                            }

                                            return (
                                                <div key={i} className="col-md-4 pb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="status">{x.item_name} => ({x.item_id})</label>
                                                        <input
                                                            onChange={(e) => {
                                                                onIncomeSettingChange(e, i, x)
                                                            }}
                                                            className='form-control score'
                                                            id={`input${x.item_id}`}
                                                            item_name={x.item_name}
                                                            item_id={x.item_id}
                                                            key={i}
                                                            type="number"
                                                            name="input[]"
                                                            step="0.000000001"
                                                            defaultValue={amount_value}
                                                            placeholder="Amount"

                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div> : <div className="alert alert-info text-center h3">Please wait...</div>
                            }

                        </> : <></>
                }

                <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
            </div>
        </form>
    )
}
