import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import { API_URL } from '../../../context/connection-string';
import {Audit, currencyConverter, formatDateAndTime, moneyFormat} from '../../../context/function';
import ReportSidebar from "../../../component/report-sidebar/report-sidebar";
import PayPointDataTable from "./data-table/paypoint-data-table";

function PayrollPayPointReport(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const header = ["S/N", "Pay Point", "No Of Staff", "Monthly Gross Pay (₦)", "Monthly Net Pay (₦)"];
    const [reportData, setReportData] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        month_id: "",
        inserted_by: props.loginData[0]?.employee_id,
    })
    // let total = 0;
    const [employeeList, setEmployeeList] = useState([])
    const getData = async () => {
        const { data } = await await axios.get(`${API_URL}human-resources/employee/list`, login?.token)
        setEmployeeList(data)

        await axios.get(`${API_URL}human-resources/report/salary-months`, login?.token).then((res) => {
            setIsLoading(false)
        }).catch((e) => {
            toast.error(e.response.data)
        })

    }

    const [EmpId, setEmpId] = useState(0)
   
    const showTable = () => {

        try {
            return reportData.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index + 1}</td>
                        <td className="text-xs font-weight-bold">{item.department_name}</td>
                        <td className="text-xs font-weight-bold">{item.NoOfStaff} &emsp;
                            {/*<span style={{ cursor: 'pointer' }} onClick={() => setEmpId(item.department_id)} ><i><u>View staff</u></i></span>  &nbsp;*/}
                            {/*{EmpId === item.department_id && <span style={{ cursor: 'pointer', color:'red' }} onClick={() => setEmpId(0)} ><i><u>| Close</u></i></span>}*/}
                            {/*{*/}
                            {/*    EmpId === item.department_id && employeeList.length > 0 &&*/}
                            {/*    <table>*/}
                            {/*        {*/}
                            {/*            employeeList?.filter(x => x.pay_point === item.department_id).map((em, emI) => {*/}
                            {/*                return (*/}
                            {/*                    <tr key={emI}>*/}
                            {/*                        <td>{em.Surname}</td>*/}
                            {/*                        <td>{em.OtherNames}</td>*/}
                            {/*                    </tr>*/}
                            {/*                )*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*    </table>*/}
                            {/*}*/}
                        </td>
                        <td className="text-xs font-weight-bold">{moneyFormat(item.GrossPay)}</td>
                        <td className="text-xs font-weight-bold">{moneyFormat(item.NetPay)}</td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    const getTotal = () => {
        let total_amount = 0;
        reportData.map((item, index) => {
            total_amount += item.GrossPay;
        });
        return total_amount;
    }

    const getTotal2 = () => {
        let total_amount = 0;
        reportData.map((item, index) => {
            total_amount += item.NetPay;
        });
        return total_amount;
    }


    const getSalaryReport = async (salary_month) => {

        if (salary_month === "") {
        } else {
            setIsSubmitting(true)
            await axios.get(`${API_URL}report/payroll/paypoint/salary?salary_month=${salary_month}`, login?.token)
                .then((res) => {
                    if (res.data.length > 0) {
                        setReportData(res.data)
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error("error getting allowances")
                })
        }
    }

    useEffect(() => {
        getData();
        Audit(props.loginData[0]?.employee_id, 'Viewed Paypoint Report');
    }, [])


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        getSalaryReport(val)
    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Payroll Report by Paypoint"
                    pages={[{ name: 'Dashboard', link: '/' }, { name: 'Report', link: '/report/dashboard' },
                    { name: 'Payroll Report by Paypoint', link: '/report/payroll-report-by-paypointe' }]}
                />

                <div className="row">
                    <ReportSidebar />
                    <div className="col-xl-10">
                        <div className=' mb-5'>
                            <div className='card card-body'>
                                <div className="row">
                                    <div className="col-md-12 pb-3">
                                        <div className="form-group">
                                            <label htmlFor="month_id">Select Salary Month</label>
                                            <input type="month" id="month_id"
                                                className="form-control"
                                                value={formData.month_id}
                                                onChange={onChange}
                                                max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                                required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            isSubmitting ?
                                <Loader />
                                :
                                <div className="">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <h3 className="card-title">Payroll Report by Paypoint</h3>
                                                <PayPointDataTable header={header} date={formatDateAndTime(formData.month_id, 'month_and_year')} caption={`Total Amount (GROSS): ${currencyConverter(getTotal() ?? 0)} || Total Amount (NET): ${currencyConverter(getTotal2() ?? 0 )} `} body={showTable()} title="Payroll Report by Paypoint" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(PayrollPayPointReport);
