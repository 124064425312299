import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import BootstrapModal from '../../../component/modal/bootstrap-modal';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import {Audit, formatDateAndTime} from '../../../context/function';
import SalaryPercentageSettingsForm from "./salary-percentage-settings-form";

function SalaryPercentageSettings(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const columns = ["SN", "Item Name", "Amount", "Is Percentage", "DATE CREATED", "CREATED BY", "ACTION"];
    const controller = new AbortController();

    const [formData, setFormData] = useState({
        percentage_settings_id: "",
        name: "",
        amount: "",
        is_percentage: "",
        inserted_by: props.loginData[0]?.employee_id,
    })
    const getTitles = async () => {
        getData()
    }

    const getData = async (data = []) => {
        const { signal } = controller.signal;
        await axios.get(`${API_URL}human-resources/salary-percentage-settings/list`, login?.token, { signal })
            .then((res) => {
                if (res.data.length > 0) {
                    let rows = [];
                    res.data.map((x, i) => {
                        rows.push([
                            i + 1,
                            x.name,
                            x.amount,
                            x.is_percentage === 1 ? 'Yes' : 'No',
                            formatDateAndTime(x.inserted_date, "date"),
                            x?.inserted_by_name,
                            <button className='btn btn-sm btn-primary'
                                    onClick={() => {
                                        setFormData({
                                            ...formData,
                                            percentage_settings_id: x.percentage_settings_id,
                                            name: x.name,
                                            amount: x.amount,
                                            is_percentage: x.is_percentage,
                                        })
                                        setOpenModal(true);
                                    }} >
                                <i className='fa fa-edit' />&nbsp; Edit
                            </button>
                        ])
                    })
                    setTableData(rows)
                }
                setIsLoading(false);
            }).catch((e) => {
                toast.error(e.response.data)
            })
    }

    useEffect(() => {
        getTitles();
        Audit(props.loginData[0]?.employee_id, 'Viewed Salary Percentage Settings');
        return () => {
            controller.abort()
        }
    }, [])


    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const onOpenModal = (status) => {
        setFormData({
            ...formData,
            title_id: "",
            title: "",
        })
        setOpenModal(status);
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if (formData.percentage_settings_id === "") {
            await axios.post(`${API_URL}human-resources/salary-percentage-settings/add`, formData, login?.token)
                .then((res) => {
                    if (res.data.message === "success") {
                        toast.success("Item added successfully");
                        onOpenModal(false);
                        getData();
                        Audit(props.loginData[0]?.employee_id, 'Added Salary Percentage Settings');
                    } else if (res.data.message === "exists") {
                        toast.warning("Item name already exists");
                    } else {

                    }
                }).catch((e) => { toast.error(e.response.data) })
        } else {
            await axios.patch(`${API_URL}human-resources/salary-percentage-settings/update`, formData, login?.token)
                .then((res) => {
                    if (res.data.message === "success") {
                        toast.success("Item updated successfully");
                        Audit(props.loginData[0]?.employee_id, 'Updated Salary Percentage Settings');
                        onOpenModal(false);
                        getData();
                    }
                }).catch((e) => { toast.error(e.response.data) })
        }

    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Salary Percentage Settings"
                                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Human Resources', link: '/' },
                                    { name: 'Salary Percentage Settings', link: '/human-resources/job-title' }]}
                                button={<button className="btn btn-primary" onClick={() => { onOpenModal(true) }}>+ Add Salary Percentage Settings</button>}
                />
                <BootstrapModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    title="Manage Salary Percentage Settings">
                    <SalaryPercentageSettingsForm
                        values={formData}
                        onChange={onChange}
                        onSubmit={onSubmit}
                    />
                </BootstrapModal>

                <ReportTable title={"Manage Salary Percentage Settings"} columns={columns} data={tableData} height={"700px"} />

            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(SalaryPercentageSettings);
