import React from 'react'

export default function SalaryPercentageSettingsForm(props) {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="row">
                <div className="col-md-12 pb-3 mb-5">
                    <div className="form-group">
                        <label htmlFor="name">Item Name</label>
                        <input type="text" id="name" className="form-control" value={props.values.name} onChange={props.onChange} required />
                    </div>
                </div>
                <div className="col-md-6 pb-3 mb-5">
                    <div className="form-group">
                        <label htmlFor="amount">amount/percentage</label>
                        <input type="text" id="amount" className="form-control" value={props.values.amount} onChange={props.onChange} required />
                    </div>
                </div>
                <div className="col-md-6 pb-3 mb-5">
                    <div className="form-group">
                        <label htmlFor="amount">Is Percentage ?</label>
                        <select id="is_percentage" className="form-control" value={props.values.is_percentage} onChange={props.onChange} required >
                            <option value=''>Select Option</option>
                            <option value='1'>Yes</option>
                            <option value='0'>No</option>
                        </select>
                    </div>
                </div>

                <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
            </div>
        </form>
    )
}
